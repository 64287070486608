@import url('../main.css');


.dialog-title {
  background-color: var(--ibs_color_cy_blue);
  color: var(--ibs_color_white);
}

.dialog-content {
  background-color: var(--ibs_color_white);
  color: var(--ibs_color_black);
}

.dialog-actions {
  background-color: var(--ibs_color_cy_yellow);
  display: flex;
}

.dialog-buttons {
  display: flex;
  flex-grow: 1;
}
.dialog-buttons button,
.dialog-buttons button:hover {
  margin: 1px;
  flex-grow: 1;
  flex-basis: 0;
  background-color: var(--ibs_color_cy_blue);
  color: var(--ibs_color_white);
  font-size: 12px;
}
