/* styles.css */
.TableFixed {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed; /* O auto si lo prefieres */
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.TableFixed th, .TableFixed td {
  border: 1px solid #ddd;
  padding: 4px;
  text-align: left;
  word-wrap: break-word;
  min-width: 150px; /* Ajusta según necesites */
  box-sizing: border-box;
  white-space: nowrap; /* O normal en media query si lo necesitas */
  font-size: 14px;
  font-weight: normal;
}

.TableFixed th {
  color: white;
  white-space: nowrap;
}

.TableFixed th:first-child {
  background-color: #AFA559;
  width: 80px;
  border-top: none;
  border-left: none;
}

.TableFixed th:not(:first-child) {
  background-color: #094D56;
}

.TableFixed td:first-child {
  background-color: #AFA559;
  color: white;
  width: 20px;
}

.TableFixed thead tr:first-child th:first-child {
  background-color: transparent;
  color: inherit;
  border: none;
}
