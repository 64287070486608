/* styles.css */

.TableTitle {
  width: 100%;
  border-bottom: 1px solid #094D56;
  margin-top: 20px;
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  color: #094D56;
}

.TableFixedHaid {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.TableFixedHaid th, .TableFixedHaid td {
  border: 1px solid #ddd;
  padding: 4px;
  text-align: left;
  word-wrap: break-word;
  min-width: 150px;
  box-sizing: border-box;
  white-space: nowrap;
  font-size: 14px;
  font-weight: normal;
}

.TableFixedHaid th {
  color: white;
  white-space: nowrap;
}

/* Fondo para la primera fila del thead */
.TableFixedHaid thead tr:first-child th {
  background-color: #094D56;
}

.TableFixedHaid th:first-child {
  width: 80px;
  border-top: none;
  border-left: none;
}

/* Transparencia para la primera celda de la primera fila del thead */
.TableFixedHaid thead tr:first-child th:first-child {
  background-color: transparent;
  color: inherit; /* Opcional: para asegurar que el texto hereda el color */
  border: none; /* Opcional: para eliminar el borde si no lo quieres */
}

/* Fondo para la primera columna del tbody */
.TableFixedHaid tbody td:first-child {
  width: 80px;
  background-color: #AFA559;
  color: white; /* Opcional: Texto blanco para mejor contraste */
}

/* Eliminar el fondo de todas las filas del tbody */
.TableFixedHaid tbody tr td {
  background-color: transparent; /* Asegura que el resto de las celdas sean transparentes */
}


.TableFixedHaid2 {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
}

/* Fondo para la primera fila del thead */
.TableFixedHaid2 tbody tr:first-child th {
  border: 1px solid #ddd;
  background-color: #094D56;
  padding: 4px;
  font-size: 14px;
  font-weight: normal;
  color: white;
}

.TableFixedHaid2 td {
  border: 1px solid #ddd;
  padding: 4px;
  text-align: left;
}

