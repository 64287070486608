/* OVERRIDE */

:root {
  /* colors */
  --cal_color_week: var(--ibs_color_blue_light);
  --cal_color_list: var(--ibs_color_green_light);
  --cal_color_day: var(--ibs_color_yellow_light);
  --cal_color_res: var(--ibs_color_red_light);
}

.fc .fc-col-header-cell {
  background-color: var(--ibs_color_gray_light);
  /* background-color: var(--ibs_color_gray); */
  /* background-image: linear-gradient(0deg, var(--ibs_color_gray) 0%, #d3d3d3 74%); */
}

.fc .fc-col-header-cell-cushion {
  /* needs to be same precedence */
  color: var(--ibs_color_black);
  font-family: Verdana, Tahoma, sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  padding-bottom: 5px;
}

.fc .fc-toolbar-title {
  color: var(--ibs_color_black);
  font-size: 20px;
  margin-left: 0px;
}

.fc-toolbar h2 {
  display: inline;
}

.fc .fc-button-primary {
  height: 28px;
  padding-top: 4px;
  font-size: 12px;
  text-transform: uppercase;
  font-family: Verdana, Tahoma, sans-serif;
  border-radius: 2px;
}

.fc .fc-button:hover {
  /* background-image: linear-gradient(0deg, var(--ibs_color_gray) 0%, #d3d3d3 74%); */
  background-color: var(--ibs_color_gray_light);
  color: var(--ibs_color_black_dark);
}

.fc-button {
  box-shadow: none !important;
}

.fc .fc-customButtonPrev-button, .fc-customButtonNext-button {
  width: 50px;
}

.fc .fc-customButtonWeek-button {
  background-color: var(--cal_color_week);
  color: var(--ibs_color_black);
}
.fc .fc-customButtonWeek-button:hover {
  background-color: var(--cal_color_week);
  color: var(--ibs_color_black_dark);
}

.fc .fc-customButtonListWeek-button {
  background-color: var(--cal_color_list);
  color: var(--ibs_color_black);
}
.fc .fc-customButtonListWeek-button:hover {
  background-color: var(--cal_color_list);
  color: var(--ibs_color_black_dark);
}

.fc .fc-customButtonDay-button {
  background-color: var(--cal_color_day);
  color: var(--ibs_color_black);
}
.fc .fc-customButtonDay-button:hover {
  background-color: var(--cal_color_day);
  color: var(--ibs_color_black_dark);
}

.fc .fc-customButtonRes-button {
  background-color: var(--cal_color_res);
  color: var(--ibs_color_black);
}
.fc .fc-customButtonRes-button:hover {
  background-color: var(--cal_color_res);
  color: var(--ibs_color_black_dark);
}

.fc .fc-customButtonToday-button {
  background-color: var(--ibs_color_gray_light);
  color: var(--ibs_color_black_dark);
  width: 100px;
}
.fc .fc-customButtonPrev-button {
  background-color: var(--ibs_color_gray_light);
  color: var(--ibs_color_black);
}
.fc .fc-customButtonNext-button {
  background-color: var(--ibs_color_gray_light);
  color: var(--ibs_color_black);
}

.fc .fc-timegrid-event {
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.fc .fc-event-main-frame {
  /* afectan a las casillas de eventos */
  padding: 0px 2px 0px 2px;
  font-family: Arial, Helvetica, sans-serif;
}

.fc .fc-event-time {
  /* afectan a las casillas de eventos */
  font-size: 12px;
}
.fc .fc-event-title {
  /* afectan a las casillas de eventos */
  font-size: 12px;
}

.fc-day-sat,
.fc-day-sun {
  color: #337ab7;
  border-color: black;
  background-color: #ebebeb;
}

/* alternar colores de fila
.fc-timegrid-slots tr:nth-of-type(even) {
  background: #CCC;
}
*/

/* altura de filas */
.fc .fc-timegrid-slot {
  height: 32px;
}