/* estilos generales */

:root {
  /* colors */
  --ibs_color_cy_blue: #094D56;
  --ibs_color_cy_yellow: #AFA559;
  /* ------------------------- */
  --ibs_color_blue: #2951a1;
  --ibs_color_blue_light: #E3EBFB;
  --ibs_color_yellow: #c4a703;
  --ibs_color_yellow_light: #FFFBE6;
  --ibs_color_red: #db2d27;
  --ibs_color_red_light: #fbd9d3;
  --ibs_color_red_dark: #681919;
  --ibs_color_brown: #a18a6e;
  --ibs_color_gray: #777575;
  --ibs_color_gray_light: #ebebeb;
  --ibs_color_black: #404040;
  --ibs_color_black_dark: #000;
  --ibs_color_white: #ffff;
  --ibs_color_white_transparent: rgba(255,255,255,0.5);
  --ibs_color_green_light: #deecde;
  --ibs_color_green: #1b855a;
  --ibs_color_green_dark: #104d35;
  /* ------------------------- */
  --ibs_color_disabled: #9e9e9e;
  --ibs_color_focused: #2951a1;
  --ibs_color_unfocused: #777575;
  /* ------------------------- */
  --ibs_color_toggle_green: #36A954;
  --ibs_color_toggle_blue: #278ae7;
  --ibs_color_toggle_yellow: #fab85c;
  --ibs_color_toggle_red: #e22518;
  /* ------------------------- */
  --ibs_color_snackbar_error: #e22518;
  --ibs_color_snackbar_warning: #FDA424;
  --ibs_color_snackbar_info: #278ae7;
  --ibs_color_snackbar_success: #36A954;

  --ibs_container_margin: 10px;
  --ibs_container_gap: 10px;

  /* main */
  --ibs_brand_font_family: 'Cambria', 'Arial';
  --ibs_appbar_height: 50px;
  
  /* controls */
  --ibs_control_text_font_family: 'Verdana';
  --ibs_control_label_font_family: 'Verdana';
  --ibs_control_toolbar_popper_font_size: 13px; /* DAQ 2024.04.12 - OJO, no dar más tamaño xq luego no cabe el texto */
  --ibs_control_label_font_size: 16px; /* DAQ 2024.01.10 - OJO, no dar más tamaño xq luego no cabe el texto */
  --ibs_control_label_font_size_radio: 14px;
  --ibs_control_text_font_size: 14px;

  --ibs_control_width_xs: 164px; /* DAQ 2022.06.18 - para algunos CxNumber */
  --ibs_control_width_sm: 200px; /* DAQ 2022.06.18 - para CxTable */
  --ibs_control_width_md: 300px; /* DAQ 2022.06.18 - para CxDialog */
  --ibs_control_width_lg: 310px; /* DAQ 2023.03.14 - NO AUMENTAR, PORQUE NO ENTRARÁ EN MÓVILES DE PANTALLA PEQUEÑA */

}

html {
  overflow-y: scroll; /* DAQ 2023.06.01 - xq el tooltip provocaba que apareciera un scroll vertical a veces */
}

.ibs_highlight { /* react-highlight-words tiene prefijados valores si no se incluye una clase como ésta */
  background-color: yellow;
  /* font-weight: bold; */
}

body .ibs_fieldset {
  /* https://getcssscan.com/css-box-shadow-examples */
  border: 1px solid var(--ibs_color_cy_blue);
  border-radius: 8px;
  margin: 5px 0px 10px 0px;
  padding: 5px 20px 8px 20px;
  box-shadow: rgba(33, 35, 38, 0.4) 0px 10px 10px -10px;

  display: flex;
  flex-flow: row wrap;
  gap: 10px;
}

body .ibs_fieldset_accordion {
  /* https://getcssscan.com/css-box-shadow-examples */
  border: 1px solid var(--ibs_color_gray_light);
  border-radius: 0px;
  margin: 5px 0px 10px 0px;
  padding: 5px 10px 8px 10px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

body .ibs_span {
  color: var(--ibs_color_cy_yellow);
  padding: 0px;
  margin: 4px 0 20px 0;
  border: none;
  outline: none;
  text-transform: uppercase;
  font: 14px sans-serif;
  font-weight: bold;
}

body .ibs_checkbox > input {
  margin: 0px 4px 0px 4px;
  height: 15px;
  width: 15px;
  accent-color: #094D56;
}

/* button ----------------------------------------------------------------------------------------- */
body .ibs_button_link {
  background: none!important;
  border: none;
  padding: 0!important;
  color: #069;
  text-decoration: none;
  font: 11px var(--ibs_control_text_font_family);
  cursor: pointer;
}

body .ibs_button_link:hover:enabled {
  color: var(--ibs_color_red);
  text-decoration: underline;
  cursor: pointer;
}

body .ibs_button_link:disabled {
  color:  var(--ibs_color_gray);
  cursor: default;
}

body .ibs_button {
  background-color: var(--ibs_color_cy_blue);
  color: var(--ibs_color_white);
  border-radius: 6px;
  padding: 10px;
  margin: 4px 0 4px 0;
  border: none;
  outline: none;
  width: 100px;
  text-transform: uppercase;
  font: 12px sans-serif;
}
body .ibs_button:hover {
  background-color: var(--ibs_color_cy_yellow);
  cursor: pointer;
}
body .ibs_button:disabled {
  background-color: #9E9E9E;
  cursor: pointer;
}

body .ibs_button_plain {
  border: 1px solid var(--ibs_color_gray);
  border-radius: 3px;
  height: 26px;
  margin: 0px 4px 0px 0px;
  padding: 2px 4px 2px 4px;
  color: var(--ibs_color_cy_blue);
  background: var(--ibs_color_white_transparent);
  font-family: var(--ibs_control_label_font_family);
  font-size: var(--ibs_control_toolbar_popper_font_size);
  white-space: nowrap; /* para que el texto no exceda el botón */
  overflow: hidden; /* para que el texto no exceda el botón */
  text-overflow: ellipsis; /* para que el texto no exceda el botón */
}
body .ibs_button_plain:hover {
  color: var(--ibs_color_black_dark);
  background-color: var(--ibs_color_gray_light);
  cursor: pointer;
}
body .ibs_button_plain:disabled {
  background-color: #9E9E9E;
  cursor: pointer;
}

/* iconbutton ------------------------------------------------------------------------------------- */
body .ibs_iconbutton {
  margin: 0px;
  padding: 2px;
  background-color: transparent;
  color: var(--ibs_color_cy_blue);
}
body .ibs_iconbutton:hover {
  background-color: transparent;
  color: var(--ibs_color_cy_yellow);
}

body .ibs_iconbutton_table_header {
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  color: var(--ibs_color_white);
}

body .ibs_iconbutton_table_body {
  margin: 0px;
  padding: 0px;
  color: var(--ibs_color_black);
}
body .ibs_iconbutton_table_body:hover {
  color: var(--ibs_color_red);
}

body .ibs_iconbutton_table {
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  color: var(--ibs_color_cy_blue);
}

body .ibs_iconbutton_table:hover {
  background-color: transparent;
  color: var(--ibs_color_cy_yellow);
}

body .ibs_iconbutton_toolbar {
  margin: 0px;
  padding: 2px;
  background-color: 'transparent';
  color: var(--ibs_color_cy_blue);
}
body .ibs_iconbutton_toolbar:hover {
  color: var(--ibs_color_red);
}
