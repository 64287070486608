/* estilos generales */
/* TABLAS */

:root {
  /* colors */
  --ibs_color_cy_blue: #094D56;
  --ibs_color_cy_yellow: #AFA559;
  /* ------------------------- */
  /* controls */
  --ibs_table_row_height: 40px;
  --ibs_table_color_main: var(--ibs_color_black);
  --ibs_table_color_bg: var(--ibs_color_white);

}

body .table_search_container {
  display: flex;
  justify-content: center;
}
.ibs_table {
  min-width: 0;
  table-layout: fixed;
  margin-top: 10px;
  border-collapse: separate !important;
  border-spacing: 0;
  border: solid #ccc 1px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.ibs_table td, .ibs_table th {
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  padding: 0px 5px 0px 5px;
  font-size: var(--ibs_control_text_font_size);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ibs_table th {
  background-color: var(--ibs_color_cy_yellow);
  color: var(--ibs_color_white);
  font-weight: normal;
  font-family: var(--ibs_control_label_font_family);
  /* text-shadow: 1px 1px var(--ibs_color_gray); */
  /* 
  background-image: -moz-linear-gradient(center top , var(--ibs_color_yellow_light), var(--ibs_color_cy_yellow));
  background-image: -webkit-gradient(linear, 0 0, 0 bottom, from(var(--ibs_color_yellow_light)), to(var(--ibs_color_cy_yellow)), color-stop(.4, var(--ibs_color_yellow_light)));
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  */
  border-top: medium none;
  /* box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8) inset; */
  cursor: default;
  line-height: 20px;
}
.ibs_table td:first-child, .ibs_table th:first-child {
  border-left: none;
}
.ibs_table th:first-child {
  -moz-border-radius: 3px 0 0 0;
  -webkit-border-radius: 3px 0 0 0;
  border-radius: 3px 0 0 0;
}
.ibs_table th:last-child {
  -moz-border-radius: 0 3px 0 0;
  -webkit-border-radius: 0 3px 0 0;
  border-radius: 0 3px 0 0;
}
.ibs_table th:only-child{
  -moz-border-radius: 3px 3px 0 0;
  -webkit-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}
.ibs_table tr:nth-child(even) {background-color: #f2f2f2;}
.ibs_table tr {
  height: var(--ibs_table_row_height); /* ALTURA DE FILAS */
}
.disabled th {
  background-color: var(--ibs_color_disabled);
  color: var(--ibs_color_white);
}
.ibs_table tr:last-child td:first-child {
  -moz-border-radius: 0 0 0 3px;
  -webkit-border-radius: 0 0 0 3px;
  border-radius: 0 0 0 3px;
}
.ibs_table tr:last-child td:last-child {
  -moz-border-radius: 0 0 3px 0;
  -webkit-border-radius: 0 0 3px 0;
  border-radius: 0 0 3px 0;
} 

body .table_cell { color: var(--ibs_color_black); }

body .table_cell_color_red { color: #B22222; }
body .table_cell_color_green { color: #2E8B57; }
body .table_cell_color_blue { color: #4169E1; }
body .table_cell_color_brown { color: #A0522D; }
body .table_cell_color_yellow { color: 	#999900; }
body .table_cell_color_pink { color: #DB7093; }
body .table_cell_color_gray { color: var(--ibs_color_gray); }
body .table_cell_color_ref_ul { margin: 0px; }
body .table_cell_color_ref_hr { border-top: 1px solid var(--ibs_color_gray_light); }

body .table_cell_cursor_default { cursor: default; }
body .table_cell_cursor_pointer { cursor: pointer; }
body .table_cell_cursor_notallowed { cursor: not-allowed; }

body .table_button_container {
  display: flex;
  justify-content: center;
  margin: 15px 0px 5px 0px;
}
body .table_button_container_side {
  border-top: 1px solid var(--ibs_color_gray_light);
  width: 100%;
}
body .table_button_container_center {
  display: flex;
  flex-direction: row;
  border-left: 2px solid var(--ibs_color_gray_light);
  border-right: 2px solid var(--ibs_color_gray_light);
  border-bottom: 2px solid var(--ibs_color_gray_light);
  border-radius: 0 0 15px 15px;
}
body .table_toolbar {
  display: flex;
  justify-content: center;
  margin: 5px 0px 30px 0px;
}
body .table_button {
  background-color: var(--ibs_table_color_bg);
  color: var(--ibs_table_color_main);
  padding: 3px; /* controla tamaño */
  margin-left: 2px;
  margin-right: 2px;
}
body .table_button:hover {
  background-color: var(--ibs_table_color_main);
  color: var(--ibs_color_white);
}
body .table_button:disabled {
  background-color: var(--ibs_color_disabled);
  color: var(--ibs_color_white);
}

body .table_label_page {
  background-color: var(--ibs_color_gray_light);
  color: var(--ibs_table_color_main);
  border: 1px dotted gray;
  border-radius: 5px;
  height: 22px; 
  width: 60px;
  margin: 5px 0px 0px 0px;
  padding: 0px 0px 0px 0px; 
  cursor: pointer;
  text-align: center;
  font-size: 16px;
}
body .table_label_page:hover {
  background-color: var(--ibs_table_color_main);
  color: var(--ibs_color_white);
}
